<template>
  <b-form @submit.prevent="save">
    <validation-observer ref="elementForm" #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon icon="StarIcon" size="25" class="mr-0 mr-sm-50" variant="primary" />
            <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.object.service_cost') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="4">
              <b-form-group label-for="arl">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.arl') }}</span><span class="text-danger">*</span>
                <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.arl')" rules="required">
                  <v-select v-model="elementData.arl_id" :options="arlList" label="name" value="id" :reduce="option => option.id" :placeholder="$t('list.defaultOption')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group label-for="service_type">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.service_type') }}</span><span class="text-danger">*</span>
                <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.service_type')" rules="required">
                  <v-select v-model="elementData.type_id" :options="serviceTypeList" label="value" value="id" :reduce="option => option.id" :placeholder="$t('list.defaultOption')" @input="onTypeChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="service_type">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.service_description') }}</span><span class="text-danger">*</span>
                <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.service_type')" rules="required">
                  <v-select v-model="elementData.service_id" :options="internalServiceList" label="cups_name" value="id" :reduce="option => option.id" :placeholder="$t('list.defaultOption')" @input="onServiceChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4" xl="4">
              <b-form-group label-for="cups">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.cups_cum_name') }}</span>
                <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.cups_cum_name')">
                  <b-form-input id="cups" v-model="elementData.service.cups" :disabled="true" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" xl="4">
              <b-form-group label-for="cost">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.cost') }}</span>
                <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.cost')">
                  <b-input-group>
                    <b-input-group-append>
                      <b-input-group-text class="bg-transparent font-weight-bold">$</b-input-group-text>
                    </b-input-group-append>
                    <b-form-input id="cost" v-model="elementData.cost" maxlength="8" pattern="^[0-9]{8}" type="number" :formatter="numberFormat" prefix="$" @input="updateTotalCost" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Nuevo campo para IVA -->
            <b-col cols="4" xl="4">
              <b-form-group label-for="iva">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.iva') }} (%)</span><span class="text-danger">*</span>
                <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.iva')" rules="required">
                  <b-form-input id="iva" v-model="elementData.iva" @input="updateTotalCost" :placeholder="$t(resourcesElementName + '.object.placeholder')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Nuevo campo para Valor del servicio incluido IVA -->
            <b-col cols="4" xl="4">
              <b-form-group label-for="total_cost">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.total_cost') }}</span>
                <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.total_cost')">
                  <b-form-input id="total_cost" v-model="elementData.total_cost" :disabled="true" readonly />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4" md="4">
              <date-picker :element-required="true" element-field="date_from" :root-element-data="elementData" :element-label="$t(resourcesElementName + '.object.date_from')" />
            </b-col>
            <b-col cols="4" md="4">
              <date-picker :element-required="true" element-field="date_to" :root-element-data="elementData" :element-label="$t(resourcesElementName + '.object.date_to')" />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="router().go(-1)">
          {{ $t('actions.back') }}
        </b-button>
        <b-button variant="primary" type="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :disabled="invalid">
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm, BInputGroupAppend, BInputGroupText, BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import router from '@/router'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    DatePicker, BInputGroup, BInputGroupAppend, BInputGroupText, BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    serviceTypeList: {
      type: Array,
      required: true,
    },
    arlList: {
      type: Array,
      required: true,
    },
    serviceList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      internalServiceList: [],
    }
  },
  watch: {
    elementLogo(newValue, oldValue) {
      this.logo = newValue
    },
  },
  mounted() {
    this.internalServiceList = this.serviceList.filter(element => element.service_type_id == this.elementData.type_id)
    this.updateTotalCost()
  },
  methods: {
    router() {
      return router
    },
    numberFormat(value) {
      return value > 99999999 ? 99999999 : value
    },
    onServiceChange() {
      this.elementData.service = this.serviceList.find(element => element.id == this.elementData.service_id)
      this.updateTotalCost()
    },
    onTypeChange() {
      this.elementData.service = {}
      this.internalServiceList = this.serviceList.filter(element => element.service_type_id == this.elementData.type_id)
    },
    updateTotalCost() {
      this.$nextTick(() => {
        const cost = parseFloat(this.elementData.cost) || 0
        const iva = parseFloat(this.elementData.iva) || 0
        this.elementData.total_cost = parseInt(cost + (cost * iva / 100))
      })
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if ('service_type' in this.elementData) {
            delete this.elementData.service_type
          }
          this.$parent.save(this.elementData)
        }
      })
    },
  },
}
</script>

<style>
</style>
